import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import FlightIcon from "@material-ui/icons/Flight";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import LoginActions, { LoginSelectors } from "../../Redux/LoginRedux";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Grid, Card, Modal, Box, Paper, Button } from "@material-ui/core";
import LoginSignup from "../../components/LoginSignup/LoginSignup";
import StoreLoginActions, {
  StoreLoginSelectors,
} from "../../Redux/StoreLoginRedux";
import styles from "./style.module.scss";
import { toast } from "react-toastify";
import PhoneOrderHistoryModal from "../PhoneOrderHistoryModal/PhoneOrderHistoryModal";
import createUrlToScreen from "../../Services/Utils";
import FlightStatus from "../FlightStatus/FlightStatus";

class NavBar extends Component {
  constructor(props) {
    console.log("NavBar props ", props);
    const storedData = JSON.parse(localStorage.getItem("showAppointmentData"));
    const show_appointment_res = storedData?.showAppointmentResponse;
    console.log("NavBar storedData ", show_appointment_res);
    super(props);
    this.state = {
      menuAnchorElement: null,
      userMenuOpen: false,
      showloginModal: false,
      phoneOrder: false,
      showOrderHistoryModal: false,
      flightStatus: false,
      currentURL: "",
      navAppointmentData: show_appointment_res,
    };
  }

  componentDidMount() {
    this.setPhoneOrder();
    this.setState({ currentURL: window.location.href });
  }

  setPhoneOrder = () => {
    const location = this.props.location.pathname.split("/");
    if (location[1] == "web" && this.state.phoneOrder) {
      this.setState({ phoneOrder: false });
    } else if (location[1] == "phone-order" && !this.state.phoneOrder) {
      this.setState({ phoneOrder: true });
    } else {
      this.setState({ phoneOrder: false });
    }
  };

  handleClose = () => {
    this.setState({ userMenuOpen: false, menuAnchorElement: null });
  };

  signOut = () => {
    // Go to login screen on logout for phone order
    if (this.state.phoneOrder) {
      this.props.dispatch(StoreLoginActions.logoutUser());
      this.props.history.push(createUrlToScreen("", this.props.match.url));
    } else {
      this.props.dispatch(LoginActions.logOut());
      this.setState({ userMenuOpen: false });
    }
  };

  logIn = () => {
    this.setState({ showloginModal: true, userMenuOpen: false });
  };

  getOrderHistoryUrl = () => {
    return "/orderHistory";
  };

  render() {
    return (
      <>
        <AppBar position="sticky" color="secondary">
          <Toolbar>
            {/* <span style={{ flexGrow: 1 }} className="font20Bold">
              {this.props.ootg && "Order On The Go"}
              {!this.props.ootg &&
                (this.props.restaurantInfo?.info?.is_service
                  ? this.state.navAppointmentData?.business_name
                  : this.props.restaurantInfo?.info?.business_name)}
            </span> */}
            <span style={{ flexGrow: 1 }} className="font20Bold">
              {this.props.ootg && "Order On The Go"}
              {!this.props.ootg &&
                (this.props.location.pathname.includes("showAppointment")
                  ? this.state.navAppointmentData?.business_name
                  : this.props.restaurantInfo?.info?.business_name)}
            </span>

            {!this.props.webMenu && (
              <>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(event) =>
                    this.setState({
                      menuAnchorElement: event.target,
                      userMenuOpen: true,
                    })
                  }
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.menuAnchorElement}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  open={this.state.userMenuOpen}
                  onClose={this.handleClose}
                >
                  {(this.state.phoneOrder
                    ? this.props.isStoreUserLoggedIn
                    : this.props.isUserLoggedIn) && (
                    <>
                      <MenuItem>
                        <strong>
                          {this.state.phoneOrder
                            ? this.props.storeUserInfo.name
                            : this.props.userInfo.customer_name}
                        </strong>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          // Open Order History dialog for phone order
                          if (this.state.phoneOrder) {
                            this.setState({
                              showOrderHistoryModal: true,
                              userMenuOpen: false,
                            });
                          } else {
                            this.setState({ userMenuOpen: false });
                            // if (this.state.currentURL.includes("order.cpos")) {
                            if (this.state.currentURL.includes("ordercpos")) {
                              window.open(
                                "https://ordercpos.orderonthego.ca/orderHistory",
                                "_blank"
                              );
                              // window.open(
                              //   "https://order.cpos.com/orderHistory",
                              //   "_blank"
                              // );
                            } else {
                              window.open(
                                "https://qaorder.orderonthego.com/orderHistory",
                                "_blank"
                              );
                              // window.open(
                              //   "https://order.orderonthego.com/orderHistory",
                              //   "_blank"
                              // );
                            }
                            // this.props.history.push(this.getOrderHistoryUrl());
                          }
                        }}
                      >
                        Order History
                      </MenuItem>

                      <MenuItem onClick={this.signOut}>Logout</MenuItem>
                    </>
                  )}
                  {!this.props.isUserLoggedIn && !this.state.phoneOrder && (
                    <MenuItem onClick={() => this.logIn()}>Log In</MenuItem>
                  )}
                </Menu>
              </>
            )}
            {this.props.webMenu && this.props.flight && (
              <IconButton
                aria-label="Flight Status"
                aria-haspopup="true"
                onClick={(event) =>
                  this.setState({
                    flightStatus: true,
                  })
                }
                color="inherit"
              >
                <FlightIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Modal open={this.state.showloginModal} disableBackdropClick>
          <div className={styles.loginModalStyle}>
            <LoginSignup
              nextScreen="null"
              onClose={() => this.setState({ showloginModal: false })}
            />
          </div>
        </Modal>
        <Modal
          open={this.state.showOrderHistoryModal}
          onBackdropClick={() =>
            this.setState({ showOrderHistoryModal: false })
          }
        >
          <div className={styles.loginModalStyle}>
            <PhoneOrderHistoryModal phoneOrder={true} />
          </div>
        </Modal>
        <Modal
          open={this.state.flightStatus}
          onBackdropClick={() => this.setState({ flightStatus: false })}
        >
          <div className={styles.loginModalStyle}>
            <FlightStatus />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    userInfo: LoginSelectors.getUserInfo(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
    isStoreUserLoggedIn: StoreLoginSelectors.isUserloggedIn(state),
    storeUserInfo: StoreLoginSelectors.getUserInfo(state),
  };
};

export default withRouter(connect(mapStateToProps)(NavBar));
